import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_forms_filter = _resolveComponent("forms-filter")!
  const _component_forms_table = _resolveComponent("forms-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_forms_filter, {
      search: _ctx.search,
      "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
      "search-placeholder": "Search",
      selected: _ctx.selected
    }, null, 8, ["search", "selected"]),
    _createVNode(_component_forms_table, {
      modelValue: _ctx.selected,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selected) = $event)),
      headers: _ctx.tableHeaders,
      items: _ctx.tableItems,
      "show-selection": "",
      "item-key": "id",
      "items-name": "forms"
    }, null, 8, ["modelValue", "headers", "items"])
  ]))
}